<template>
  <div id="PlanDetailView" style="height: 100%">
    <van-overlay :show="loading" style="text-align: center">
      <van-loading type="spinner">加载中...</van-loading>
    </van-overlay>
    <van-empty v-if="empty.isShow" :image="empty.image" :description="empty.description" />
    <div v-else>
      <a-row>
        <a-col :xs="24" :sm="24" :lg="8" :xl="8">
          <div style="background-color: #F2F6FC; border-radius: 10px;margin:10px">
            <div style="margin: 10px;">
              <div style="height: 20px"></div>
              <h1 v-text="planInfo.planName"></h1>
              <div style="text-align: left" v-html="detailInfo.planPriceInfo"></div>
              <div style="text-align: right; padding: 10px">
                <div>保费：<span style="font-size: 30px; color: #E6A23C">{{ planInfo.premium }} 元</span></div>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :lg="16" :xl="16">
          <div style="margin: 20px 10px 10px 10px;">
            <p style="text-align: left" v-html="detailInfo.planIntroduction"></p>
          </div>
          <div style="text-align: right; margin-right: 20px">
            <a-button type="primary" @click="insure">立即投保</a-button>
          </div>
        </a-col>

        <a-col :span="24" style="margin-top: 16px">
          <div style="margin: 20px 10px 10px 10px;">
            <a-tabs type="card" :default-active-key="1">
              <a-tab-pane v-for="(item, i) in detailInfo.planTipTabInfo" :key="i" :tab="item.title">
                <div style="margin: 10px; text-align: left"  v-html="item.content"></div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-col>

        <a-col :span="24" style="margin-top: 10px; background-color: #E4E7ED">
          <a-card style="margin-top: 20px; text-align: left" title="">
            <p v-html="detailInfo.bottomTipsHtml"></p>
          </a-card>
        </a-col>
      </a-row>

      <a-modal
          title="请逐页阅读下面信息"
          :visible="visible"
          width="90%"
          okText="已阅读"
          @cancel="visible = false"
          @ok="handleOk">
        <div id="readTop"></div>
        <a-tabs type="card" :default-active-key="readIndex" :active-key="readIndex" @change="readChange">
          <a-tab-pane v-for="(item, i) in detailInfo.planTipTabInfo" :key="(i + 1)" :tab="item.title">
            <div style="margin: 10px; text-align: left"  v-html="item.content"></div>
          </a-tab-pane>
        </a-tabs>
      </a-modal>
    </div>

    <a-modal
        title="温馨提示，您已进入投保流程"
        :visible="insureTips"
        :closable= false>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="insureTips = false">
          知道了
        </a-button>
      </template>
      <p v-html="detailInfo.tipInfoHtml"></p>
    </a-modal>

  </div>
</template>
<script>
import {getChannelProductList} from "@/api/insure";

export default {
  name: "PlanDetail",
  mounted() {
    this.onLoad();
  },
  data() {
    return {
      loading: false,
      visible: false,
      insureTips: false,
      readIndex: 1,
      formDate:{
        channelCode:"",
        productCode: null,
        planCode: null,
        tempNo: null
      },
      planInfo: {},
      detailInfo: {},
      empty:{
        isShow:false,
        image:"default",
        description:"信息不存在",
      },
    }
  },
  methods: {
    onLoad() {
      this.formDate.channelCode = this.$route.query.channelCode;
      this.formDate.productCode = this.$route.query.productCode;
      this.formDate.planCode = this.$route.query.planCode;
      this.formDate.tempNo = this.$route.query.tempNo;
      this.loading = true;
      getChannelProductList({
        channelCode: this.formDate.channelCode,
        productCode: this.formDate.productCode,
        planCode: this.formDate.planCode,
        tempNo: this.formDate.tempNo,
        pageNum: 1,
        pageSize:100}).then(res => {
        if (200 === res.code) {
          if (0 === res.data.length) {
            this.empty.isShow = true;
            return;
          }
          this.planInfo = res.data[0];
          if (this.planInfo.detailInfo) {
            this.detailInfo = JSON.parse(this.planInfo.detailInfo);
            if (this.detailInfo.tipInfoHtml) {
              this.insureTips = true;
            }
          }

        }else {
          this.empty.isShow = true;
          this.empty.description = res.message;
        }
        this.loading = false;
      }).catch(()=>{
        this.loading = false;
        this.empty.isShow = true;
        this.empty.image = "network";
        this.empty.description = "请求失败";
      });
    },

    insure() {
      this.readIndex = 1;
      this.visible = true;
    },
    readChange(activeKey){
      this.readIndex = activeKey;
    },
    handleOk(){
      if (this.readIndex === this.detailInfo.planTipTabInfo.length) {
        this.$router.push({path: '/insure/insure/', query: {channelCode: this.formDate.channelCode, planCode: this.formDate.planCode}});
      } else {
        const element = document.getElementById('readTop');
        element.scrollIntoView();
        this.readIndex = this.readIndex + 1;
      }
    }

  }
}
</script>
